export default {
  sections: object => {
    return [
      {
        editable: true,
        title: 'Общие сведения',
        params: [
          {
            title: 'тип нарушения',
            key: 'violation_type_id'
          },
          {
            title: 'собственник известен',
            key: 'owner_is_known'
          },
          {
            title: 'ИНФОРМАЦИЯ О СОБСТВЕННИКЕ',
            key: 'owner_info'
          },
          {
            title: 'ВИД РЕКЛАМНОЙ КОНСТРУКЦИИ',
            key: 'obj_type_id'
          },
          {
            title: 'КОЛИЧЕСТВО ИНФОРМАЦИОННЫХ ПОЛЕЙ',
            key: 'panel_count'
          },
          {
            title: 'РАЗМЕР ИНФОРМАЦИОННОГО ПОЛЯ, м',
            key: 'info_view_size'
          },
          {
            title: 'ПЛОЩАДЬ ИНФОРМАЦИОННОГО ПОЛЯ, кв.м.',
            key: 'info_view_square'
          }
        ]
      },
      {
        editable: true,
        title: 'АДРЕС',
        params: [
          {
            title: '',
            key: 'address_district_id'
          },
          {
            title: 'ПОЯСНЕНИЯ К АДРЕСУ',
            key: 'address_info'
          },
          {
            map: true,
            title: 'НА КАРТЕ',
            key: 'geo_polygon'
          }
        ]
      },

      {
        title:
          'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ РЕКЛАМНОЙ КОНСТРУКЦИИ',
        params: [
          {
            title: '№',
            key: 'audit_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_audit_doc_files'
          },
          {
            title: 'ФОТОГРАФИИ',
            key: 'file_audit_doc_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
        params: [
          {
            title: 'НАЛОЖЕНЫ ЛИ ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
            key: 'warranty_measures_taken'
          },
          {
            title: 'КОММЕНТАРИЙ',
            key: 'warranty_measures_info'
          }
        ]
      },
      {
        editable: true,
        title: 'Добровольный демонтаж',
        params: [
          {
            title: 'СВЕДЕНИЯ О ПРЕДПИСАНИИ О ДЕМОНТАЖЕ РЕКЛАМНОЙ КОНСТРУКЦИИ',
            key: ''
          },
          {
            title: '№',
            key: 'remove_doc_number'
          },
          {
            title: 'ДАТА',
            key: 'remove_doc_date'
          },
          {
            title: 'ДАТА ВЫДАЧИ',
            key: 'remove_doc_given_date'
          },
          {
            title: 'ЛИЦО, КОТОРОМУ ВЫДАНО ПРЕДПИСАНИЕ',
            key: 'remove_doc_given_to'
          },
          {
            title:
              'ДАТА ОКОНЧАНИЯ СРОКА ИСПОЛНЕНИЯ ОБЯЗАННОСТИ ПО ДЕМОНТАЖУ РЕКЛАМНОЙ КОНСТРУКЦИИ',
            key: 'fix_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_remove_order_files'
          },
          {
            title:
              'СВЕДЕНИЯ ОБ ИСПОЛНЕНИИ ОБЯЗАННОСТИ ПО ДЕМОНТАЖУ РЕКЛАМНОЙ КОНСТРУКЦИИ',
            key: 'title'
          },
          {
            title: 'АКТ ПРОВЕРКИ ИСПОЛНЕНИЯ, №',
            key: 'fix_doc_number'
          },
          {
            title: 'АКТ ПРОВЕРКИ ИСПОЛНЕНИЯ, ДАТА',
            key: 'fix_doc_date'
          },
          {
            title: 'РЕЗУЛЬТАТЫ ПРОВЕРКИ ИСПОЛНЕНИЯ',
            key: 'fix_doc_result'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_remove_done_files'
          },

          {
            title: 'ФОТОГРАФИИ',
            key: 'file_remove_done_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            title:
              'РЕКВИЗИТЫ РАСПОРЯЖЕНИЯ ГЛАВЫ ТЕРРИТОРИАЛЬНОГО ОРГАНА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№ СЭД',
            key: 'remove_force_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_force_doc_date'
          },
          {
            title: 'ДАТА ПЛАНИРУЕМОГО ДЕМОНТАЖА ОБЪЕКТА',
            key: 'remove_plan_date'
          },
          {
            title: 'ДАТА ФАКТИЧЕСКОГО ДЕМОНТАЖА ОБЪЕКТА',
            key: 'remove_actual_date'
          }
        ]
      },
      {
        editable: true,
        title: 'ПОВТОРНЫЕ СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
        params: [
          {
            title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
            key: 'sec_remove_voluntary_is_done'
          },
          {
            title: '№',
            key: 'sec_remove_voluntary_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'sec_remove_voluntary_doc_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_sec_remove_voluntary_files'
          },
          {
            title: 'ФОТОГРАФИИ',
            key: 'file_sec_remove_voluntary_photos'
          }
        ]
      },
      {
        title: 'ПЕРЕМЕЩЕНИЕ И ХРАНЕНИЕ ОБЪЕКТА',
        params: [
          {
            title: 'СВЕДЕНИЯ ОБ АКТЕ О ДЕМОНТАЖЕ И ПЕРЕМЕЩЕНИИ ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№',
            key: 'remove_act_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_act_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_remove_act_files'
          },
          {
            title: 'ФОТОГРАФИИ',
            key: 'file_remove_act_photos'
          },
          {
            title: 'СВЕДЕНИЯ О ХРАНЕНИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА',
            key: 'title'
          },
          {
            _if: object.keep_place_id,
            title: 'АДРЕС ХРАНЕНИЯ ОБЪЕКТА',
            key: 'place_street&place_house'
          },
          {
            _if: object.keep_place_id,
            title: 'ЛИЦО, ОСУЩЕСТВЛЯЮЩЕЕ ХРАНЕНИЕ',
            key: 'responsible_name'
          },
          {
            _if: object.keep_date,
            title: 'ОКОНЧАНИЕ СРОКА ХРАНЕНИЯ',
            key: 'keep_date'
          },
          {
            title: 'СВЕДЕНИЯ О ВОЗВРАТЕ ДЕМОНТИРОВАННОГО ОБЪЕКТА ВЛАДЕЛЬЦУ',
            key: 'title'
          },

          {
            title: 'Объект выдан владельцу',
            key: 'object_issued_to_owner'
          },
          {
            title: 'Дата выдачи объекта',
            key: 'object_issued_to_owner_date'
          },
          {
            title: '№ акта сдачи-приемки объекта',
            key: 'object_issued_to_owner_act_number'
          },
          {
            title: 'Дата акта сдачи-приемки объекта',
            key: 'object_issued_to_owner_act_date'
          },

          {
            files: true,
            title: 'Акт сдачи-приемки',
            key: 'file_object_issued_to_owner_files'
          },
          {
            title:
              'ИНФОРМАЦИЯ О ЗАХОРОНЕНИИ ЛИБО УТИЛИЗАЦИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№',
            key: 'utilization_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'utilization_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_utilization_files'
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ВОЗМЕЩЕНИИ ПОНЕСЕННЫХ РАСХОДОВ',
        params: [
          {
            title:
              'СУММА ФАКТИЧЕСКИ ПОНЕСЕННЫХ РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ',
            key: 'remove_cost_actual'
          },
          {
            title:
              'СУММА ВОЗМЕЩЕННЫХ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ,ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА, РУБ.',
            key: 'remove_cost_refunded'
          },
          {
            title:
              'ДАТА ВОЗМЕЩЕНИЯ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ,ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА',
            key: 'remove_cost_refund_date'
          }
        ]
      }
    ];
  }
};
